<!--
 * @Author: zhongtao
 * @LastEditors: 林娴
-->
<template>
  <div class="report-inner">
    <!-- 赛事日报表 -->
    <iframe
      class="output-iframe"
      :src="url+datestr"
      frameborder="0"
    ></iframe>
    <!-- <div v-html="html"></div> -->
    <!-- <iframe class="output-iframe" id="output-frame-id" width="800px" height="600px" frameborder="0"></iframe> -->
  </div>
</template>

<script>
// import axios from 'axios'
import { mapState } from "vuex";
import dayjs from 'dayjs'

export default {
  data() {
    return {
      html: "",
    };
  },
  computed: {
    ...mapState(["TOKEN"]),
    datestr() {
      const today = dayjs().format('YYYY-MM-DD')
      return `&orderStTime=${today}&orderEndTime=${today}`
    },
  },
  mounted() {
    console.log(123)
    console.log('url', this.url)
    // this.$http
    //   .get("/operation/ureport/preview?_u=file:prod_daily.ureport.xml")
    //   .then((res) => {
    //     console.log(res.data);
    //     this.html = res.data
    //   });
    /*     function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          // this.response is a Blob, because we set responseType above
          const dataUrl = URL.createObjectURL(this.response);
          document.querySelector("#output-frame-id").src = dataUrl;
        } else {
          console.error("no pdf :(");
        }
      }
    }

    const xhr = new XMLHttpRequest();

    xhr.open("GET", "/operation/ureport/preview?_u=file:prod_daily.ureport.xml");
    xhr.onreadystatechange = handler;
    xhr.responseType = "blob";
    xhr.setRequestHeader("token", this.TOKEN);
    xhr.send(); */
    // function getContent(url) {
    /*       const url = "/operation/ureport/preview?_u=file:prod_daily.ureport.xml"
      const authToken = this.TOKEN;
      const header = { token: authToken };
      const iframe = document.getElementById("output-frame-id").contentDocument;
      this.$http
        .get(url, { headers: header })
        .then((response) => {
          iframe.write(response.data);
        })
        .catch((error) => {
          console.log(error);
        }); */
    // }
  },
};
</script>

<style lang="scss" scoped>
.report-inner {
  // height: 100%;
      height: calc(100vh - 111px);
}

.output-iframe {
  width: 100%;
  height: 100%;
}
</style>
